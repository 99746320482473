@import url('https://fonts.googleapis.com/css2?family=Philosopher&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Staatliches&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&family=Roboto:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&family=Roboto:wght@100;300&display=swap');
* {
  box-sizing: border-box;
}

/*html body */

html,
body,
#root {
  background: aliceblue !important;
}

/* NavBar CSS */
.main-div{
  flex-direction: column;
  background-image: url('../src/images/backgradient.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  height: auto !important;
}
.nav {
  /* position: fixed; */
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  z-index: 101;
  border-bottom: 1px solid #e8ecfd;
  /* background-color: aliceblue; */
}

.nav-1 {
  width: 100%;
}

.nav-2 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  min-height: 100%;
}
.announcement{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #DC859F;
    height: 40px;
    font-size: 18px;
    width: 100%;
    text-decoration: underline;
}
.announcement > p{
  cursor: pointer;
}
.nav-3 {
  width: 100%;
  height: 60px;
  overflow: visible;
  min-height: auto;
  position: relative;
}

.head-left {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-shrink: 1;
  -ms-flex-negative: 1;
  flex-shrink: 1;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 24px;
}

.head-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  padding-left: 24px;
}

.head-name {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(14, 16, 60);
  -webkit-text-decoration: none;
  text-decoration: none;
  padding: 8px;
  margin-left: -8px;
  margin-bottom: -4px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  font-family: "Philosopher", sans-serif;
}

.nav-right {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-basis: 1200px;
  -ms-flex-preferred-size: 1200px;
  flex-basis: 1200px;
  max-width: 1200px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex: 1000;
  -webkit-flex-grow: 1000;
  -ms-flex-positive: 1000;
  flex-grow: 1000;
}

.home-nav {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-basis: 760px;
  -ms-flex-preferred-size: 760px;
  flex-basis: 760px;
  padding-right: 80px;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.nav-h1 {
  position: relative;
  height: 44px;
  width: 100%;
  z-index: 0;
}

.nav-sr {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.nav-ser {
  position: relative;
  box-sizing: border-box;
  border-radius: 9px;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
}

.nav-sear {
  position: relative;
  -webkit-transition: border-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out;
  background-color: #ffffff;
  border-radius: 9px;
  border: 1px solid #e8ecfd;
  overflow: hidden;
}

.nav-sear:focus-within {
  border: 1px solid #0898ec;
}

.nav-ser:focus-within {
  background-color: #e8ecfd;
}

.nav-rc {
  display: none;
}

.nav-rr {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-basis: 460px;
  -ms-flex-preferred-size: 460px;
  flex-basis: 460px;
  max-width: 460px;
  padding-right: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: aliceblue !important;
  border-bottom: 1px solid #e8ecfd;
}

.right-cnt {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-flex-basis: 460px;
  -ms-flex-preferred-size: 460px;
  flex-basis: 460px;
  max-width: 460px;
  padding-right: 24px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.rr-btn {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 1050;
}

.nav-item {
  margin-right: 24px;
  height: 100%;
  display: flex;
}

.nav-item a + a {
  margin-left: 20px;
  text-decoration: none !important;
}

.home-btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(39, 117, 219);
  text-decoration: none !important;
  /* padding: 8px; */
  margin-left: -8px;
  margin-bottom: -4px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
}

.setting-btn {
  /* margin-top: 5px; */
  cursor: pointer;
  margin-left: 15px;
}

.toggle-btn {
  margin-top: 5px;
  cursor: pointer;
  margin-left: 15px;
}

.hm {
  height: 100%;
  position: relative;
}

.hm_active {
  font-weight: bold;
  border-bottom: 2px solid rgb(39, 117, 219);
}

.hm-ex {
  height: 100%;
  position: relative;
}
.connect-wallet{
  position: relative;
  cursor: pointer;
}
.connect-text{
  position: absolute;
  top: -28%;
  right: 8%;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
}
.btn-cc {
  border-radius: 12px;
  background-image: url('../src/images/connectwalletbg.png');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  height: 50px !important;
  width: 200px !important;
  position: relative;
  cursor: pointer;
}
.connect-text1{
  position: absolute;
  left: 40px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}
.connect-text2{
  position: absolute;
  left: 65px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;
}
.cr-btn {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  border: 1px solid rgb(39, 117, 219);
  border-radius: 12px;
  box-shadow: rgb(0 162 250 / 70%) 0px 4px 20px;
  height: 44px;
  padding: 3px 22px 0px;
  display: flex;
  color: rgb(255, 255, 255);
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: rgb(255 255 255 / 25%) 0px 4px 9px inset;
  cursor: pointer;
  font-family: "Philosopher", sans-serif;
  margin-right: 10px;
}

.btn-cr:hover {
  /* color: white; */
  text-decoration: none;
}

.hm-txt {
  padding: 3px 0px 0px 8px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #FFFFFF;

}
.card-div{
  position: relative;
  height: 390px !important;
  width: 400px !important;
}
.card-img{
  position: absolute;
  height: 390px;
  object-fit: cover;
}
/* Bottom Nav CSS */

.btm-nav {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: aliceblue;
  border-top: 1px solid rgb(232, 236, 253);
  display: none;
  z-index: 1000;
}

.btm-btns {
  margin: 10px;
  height: 50px;
  grid-template-columns: 1fr 1fr 2fr;
  display: grid;
}

.nav-btn {
  text-decoration: none;
  height: 100%;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.login-btn {
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.l-btn {
  height: 44px;
  box-shadow: rgb(255 255 255 / 25%) 0px 4px 9px inset;
  font-size: 16px;
  margin-right: 20px;
}

.lo-btn {
  width: 100%;
  background: rgb(39, 117, 219);
  border-radius: 13px;
  outline: none;
  font-weight: bold;
  font-style: normal;
  font-family: "Philosopher", sans-serif;
  line-height: 19px;
  text-align: center;
  color: rgb(255, 255, 255);
  transition: all 0.15s ease-in 0s;
  will-change: transform, background-color, box-shadow;
  cursor: pointer;
}

.log-btn {
  border: none;
  margin: 0px;
  padding: 0px;
  overflow: visible;
  font-variant: inherit;
  font-stretch: inherit;
  appearance: none;
}
.lpstaking-title{
  font-family: Philosopher;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 36px;
  text-align: center;
  color: #B42D17;
}
.lpstaking-subtitle{
  font-family: Roboto;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
}
.tablebg{
  position: relative;
}
.table-text{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  width: 150px !important;
}

.table-text1{
  font-family: Quicksand;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  width: 150px !important;
}
.tablebgimg{
  width: 600px;
  height: 210px;
}
.table-title{
  position: absolute;
  top: 50px;
  left: 70px;
}
.table-line{
  position: absolute;
  top: 100px;
  left: 110px;
}
.table-content{
  position: absolute;
  top: 120px;
  left: 80px;
}
.horiline{
  object-fit: cover;
  width: 370px;
  height: 2px;
}
.create-lp{
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  color: #FFFFFF;
}
.provide-liq{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #FFFFFF;
}
.addliq-a{
  position: relative;
}
.addliq{
  position: absolute;
  width: 300px;
  height: 45px;
}
.add-liq-btn{
  color: #fff;
  position: absolute;
  width: 300px;
  top: 10px;
  z-index: 10;
  font-family: Philosopher;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  text-align: center;
}

@media (max-width: 1200px) {
  .home-nav {
    padding-right: 40px;
  }
}

@media (max-width: 768px) {
  .home-nav {
    padding-right: 0;
  }

  .nav-sm {
    display: none;
  }

  .nav-ser {
    background-color: #e8ecfd;
  }

  /* .nav-rc {
    background-color: #ffffff;
  } */
}

@media screen and (max-width: 480px) {
  .container {
    padding-bottom: 135px !important;
  }

  .btm-nav {
    display: block;
  }

  .right-cnt {
    display: none;
  }

  .search {
    margin-right: 0px !important;
    margin-left: 0px !important;
  }

  .nav-rc {
    display: block;
    padding: 5px;
  }
}

/* Search CSS */

.boxContainer {
  /* margin: auto; */
  position: relative;
  /* width: 300px; */
  /* height: 40px; */
  border: 2px solid #3498db;
  border-radius: 12px;
  /* padding: 10px; */
  margin-bottom: 10px;
  /* display: flex; */
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.elementsContainer {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.search-bar-dropdown {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.search {
  border: none;
  height: 100%;
  width: 100%;
  padding: 10px;
  border-radius: 25px;
  font-size: 18px;
  color: #424242;
  font-weight: 500;
  margin-left: 20px;
  /* border: 1px solid #0898ec; */
  margin-right: 40px;
}

.search:focus {
  outline: none;
}

.icon {
  float: right;
  font-size: 25;
  color: #0898ec;
  cursor: pointer;
  margin-left: 30px;
}

.list-group-item {
  margin-left: 20px;
  /* border: none !important; */
  padding: 0 -10px !important;
  float: inherit;
  border-bottom: 0.75px solid #6fa7d8 !important;
}

/* Navigation CSS */
.nav_bg,
.navbar {
  background-color: white !important;
}

.navbar a {
  font-size: 18px;
  color: #273c75 !important;
  text-transform: capitalize;
}

.menu_active {
  font-weight: bold;
  border-bottom: 1px solid #273c75;
}

.nav-login {
  position: relative;
  display: inline-block;
  padding: 10px 30px;
  margin-left: 25px;
  text-decoration: none !important;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 2px;
  color: #5a84a2;
  font-size: 18px;
  border-radius: 40px;
  border: 1px solid #273c75;
  /* box-shadow: -2px -2px 8px rgba(255, 255, 255, 1),
    -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1), 2px 2px 8px rgba(0, 0, 0, 0.15); */
}

.nav-login:hover {
  /* box-shadow: inset -2px -2px 8px rgba(255, 255, 255, 1),
    inset -2px -2px 12px rgba(255, 255, 255, 0.5),
    inset 2px 2px 4px rgba(255, 255, 255, 0.1),
    inset 2px 2px 8px rgba(0, 0, 0, 0.15); */
}

.btn-active {
  font-weight: bold;
  color: #273c75;
}

/* DropDown CSS */
.drp {
  position: absolute;
  display: flex;
  width: 320px;
  z-index: 0;
  top: 25px;
  right: -35px;
  will-change: opacity, transform, visibility;
  opacity: 1;
  visibility: visible;
  transform: translate(-20px);
  box-shadow: rgba(0, 0, 0, 0.05) 20px 20px 55px,
    rgba(0, 0, 0, 0.05) 2px 4px 6px;
  pointer-events: inherit;
  background: rgb(255, 255, 255);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(232, 236, 253);
  border-image: initial;
  border-radius: 12px;
  transition: opacity 0.25s ease-in-out 0s, transform, visibility;
}

.drp-1 {
  width: 100%;
  padding: 24px 0px 8px;
}

.drp-h {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 0px 15px;
}

.drp-t {
  color: rgb(39, 117, 219);
  font-weight: 600;
}

.drp-tx {
  font-size: 15px;
  font-style: normal;
  font-family: "Philosopher", sans-serif;
}

.drp-ac {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(39, 117, 219);
  text-decoration: none;
  font-family: "Philosopher", sans-serif;
}

.token {
  padding: 0px;
  cursor: pointer;
}

.token-d {
  padding-top: 18px;
}

.token-det {
  padding: 0px 15px;
}

.token-deta {
  height: 100px;
  background: rgb(247, 247, 255);
  width: 100%;
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  border-radius: 10px;
}

.des-txt {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(0, 0, 0);
  font-family: "Philosopher", sans-serif;
}

.line {
  margin: 18px 0px;
  width: 100%;
  height: 1px;
  background: rgb(232, 236, 253);
}

.h-btm {
  padding: 0px;
  cursor: pointer;
}

.help-btn {
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  color: rgb(0, 0, 0);
  text-decoration: none !important;
  display: flex;
  /* align-items: center; */
  padding: 8px 24px;
  transition: background 150ms ease-out 0s;
}

.h-cntn {
  margin-right: 16px;
  height: 16px;
  width: 16px;
}

.h-txt {
  font-size: 16px;
  font-weight: 500;
  padding-top: 3px;
  color: rgb(0, 0, 0);
  font-family: "Philosopher", sans-serif;
}

/* Home CSS */

.container {
  width: calc(100vw - 32px);
  max-width: 375px !important;
  margin-top: 25px;
}

.card {
  background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  );
  border-radius: 8px;
  max-height: 380px;
}

.card-parts {
  background: linear-gradient(
    162.92deg,
    rgb(245 9 9) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  /*linear-gradient(
    162.92deg,
    rgb(9 245 212) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  linear-gradient(
    162.92deg,
    rgb(245 9 9) 12.36%,
    rgb(24 170 204 / 76%) 94.75%
  );
  linear-gradient(
    162.92deg,
    rgb(9 158 245) 12.36%,
    rgb(0, 0, 0) 94.75%
  );
  background:linear-gradient(
    162.92deg,
    rgb(43 69 84) 12.36%,
    rgb(0, 0, 0) 94.75%
  );*/
  box-shadow: rgb(0 0 0 / 40%) 0px 4px 20px;
  border-radius: 8px;
  color: white;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  cursor: default;
  padding: 13px;
  z-index: 1;
  transform: perspective(1000px) rotateX(0deg) rotateY(0deg) scale3d(1, 1, 1);
}

.card-top-head {
  display: none;
  top: 0;
  position: absolute;
  font-weight: 300;
  font-size: 24px;
  line-height: 126.7%;
  width: 100%;
  margin: 0px;
}

.card-top-below {
  display: none;
  position: absolute;
  top: 0;
  float: right;
  color: rgb(22, 23, 26);
  text-shadow: 0 0.75px pink;
  font-family: Philosopher;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 156.7%;
  width: 100%;
  margin: 0px;
  font-feature-settings: "tnum", "onum";
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.image-f {
  /*width: 50%;
  height: 40%;*/
  background: #0d131885;
  /*max-height: 300px;*/
  display: flex;
  align-items: center;
  box-shadow: rgb(0 0 0 / 5%) 12px 12px 0px;
}

img {
  height: 50%;
  width: 50%;
}

.image-fusi {
  display: block;
  margin: auto;
  min-width: -webkit-fill-available;
  min-height: 320px !important;
  max-height: 360px !important;

  /* background: #17b8b1;
  box-sizing: border-box;
  border-radius: 4px;
  */
}

.bottom-desc {
  position: absolute;
  bottom: 0;
  background: rgb(4 159 232 / 50%);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-top: 1rem;
}

.price {
  font-weight: 600;
  font-size: 18px;
  margin: 0px 0px 0.5rem;
}

.available {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin-left: -225px;
  font-size: 12px;
}

.right-corner {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin: 2px;
  font-size: 12px;
  float: right;
}

.left-corner {
  color: rgb(174, 174, 174);
  font-weight: 400;
  margin: 1px 0px 0px;
  font-size: 12px;
  float: left;
}

.description {
  color: rgb(0, 0, 0);
  font-weight: 500;
  margin: -12px 0px 12px;
  font-size: 14px;
  padding: 32px 20px 20px;
  border-radius: 0px 0px 8px 8px;
  background-color: rgb(192 220 245);
}

.description a {
  color: rgb(254, 109, 222);
  text-decoration: none;
}

.buy-btn {
  margin: 0.5rem 0rem;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: row;
  color: rgb(0, 0, 0);
}

.buy-btn-now {
  width: 100%;
  text-align: center;
}

.btn-css {
  margin: 2px;
  border-radius: 8px;
  box-sizing: border-box;
  height: 48px;
  /* width: 100%; */
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1;
  border: 0px solid;
  cursor: pointer;
  opacity: 1;
  pointer-events: auto;
  background: linear-gradient(
    97.28deg,
    rgb(39, 117, 219) 2.08%,
    rgb(94, 146, 223) 106.51%
  );
  box-shadow: rgb(0 162 250 / 70%) 0px 4px 20px;
  color: rgb(255, 255, 255);
  transform: scale(1);
  transition: transform 0.3s ease 0s;
}

.btn-txt {
  text-align: center;
}

/* Modal CSS */

.modal_body {
  background-color: aliceblue;
  border-bottom: none !important;
  text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

.form-control {
  border: none !important;
}

.button {
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #0984e3;
  border-radius: 100px;
  border: 3px solid #0984e3;
  border-width: 3px 3px 3px 3px;
  padding: 5px 60px 5px 20px;
  color: #ffffff;
  font-size: 16px;
  font-family: arial;
  font-weight: 900;
  font-style: normal;
}
.button-left {
  backface-visibility: hidden;
  position: relative;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  background: #d63031;
  border-radius: 100px;
  border: 3px solid #d63031;
  border-width: 3px 3px 3px 3px;
  padding: 5px 5px 5px 70px;
  color: #ffffff;
  font-size: 16px;
  font-family: arial;
  font-weight: 900;
  font-style: normal;
}
.button-left > div {
  color: #999999;
  font-size: 10px;
  font-family: Philosopher Neue;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.button > div {
  color: #999999;
  font-size: 10px;
  font-family: Philosopher Neue;
  font-weight: initial;
  font-style: normal;
  text-align: center;
  margin: 0px 0px 0px 0px;
}
.button > i {
  font-size: 1em;
  background: #74b9ff;
  border-radius: 100px;
  border: 0px solid transparent;
  border-width: 0px 13px 13px 0px;
  padding: 5px 6px 5px 16px;
  margin: 2px 2px 2px 2px;
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  width: 48px;
  height: 28px;
}
.button-left > i {
  font-size: 1em;
  background: #ff7675;
  border-radius: 100px;
  border: 0px solid transparent;
  border-width: 0px 13px 13px 0px;
  padding: 5px 6px 5px 16px;
  margin: 2px 2px 2px 2px;
  position: absolute;
  top: 0px;
  left: 10px;
  bottom: 0px;
  right: 0px;
  width: 48px;
  height: 28px;
}
.button > .ld {
  font-size: initial;
}

/* Account Icon CSS */

.rr-icon {
  display: flex;
  align-items: center;
  border-left: 1px solid black;
  padding: 0px 0px 0px 24px;
}

.btn-icon {
  z-index: 1;
  cursor: pointer;
  outline: none;
  padding: 8px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  border-radius: 999px;
}

.btn-icon1 {
  border: none;
  margin: 0px;
  padding: 0px;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  appearance: none;
}

.rr-icon button + button {
  margin-left: 18px;
}

/* @media (prefers-color-scheme: dark) {
  html,
  #root,
  .container,
  .nav,
  .btm-nav,
  .drp {
    background-color: #222f3e;
  }

  .drp {
    border-color: #1e272e;
  }

  .search,
  .token-deta {
    background-color: #576574;
    color: white;
  }

  .search::placeholder {
    color: white;
  }

  .list-group-item {
    background-color: #1e272e !important;
    color: white !important;
  }

  .list-group-item:hover {
    background-color: #576574 !important;
  }

  .hm-txt,
  .des-txt {
    color: white;
  }

  .nav {
    border-bottom: #5b8eb4;
  }

  .line {
    background: #1e272e !important;
  }
} */

/* Explore Style */

h1 {
  font-size: 3rem;
}

.ex_app {
  padding: 2rem 5rem;
}

.ex_title {
  padding-bottom: 2rem;
  text-align: center;
}

h4 {
  font-size: 2rem;
  font-weight: 200;
  margin: 1rem 0;
}

h4:hover {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.p_desc {
  background: #121fcf;
  background: -webkit-linear-gradient(to right, #121fcf 0%, #cf1512 100%);
  background: -moz-linear-gradient(to right, #121fcf 0%, #cf1512 100%);
  background: linear-gradient(to right, #121fcf 0%, #cf1512 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  font-weight: bold;
}

.item_e {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 3rem;
}

.item_con {
  width: 100%;
  padding: 1.2rem;
  /* background-color: rgb(192 220 245); */
  border-radius: 5%;
  border: 1px solid #74b9ff;
}

.item_container {
  overflow: hidden;
}

.item_container > img {
  width: 100%;
  border-radius: 5px;
  margin-bottom: 10px;
  /* transition: all 0.3s ease-in-out; */
}

/* .item_container > img:hover {
  transform: scale(1.1);
} */

.ex_s {
  padding: 1rem;
  color: #0984e3;
}

.filter_button {
  width: 70%;
  margin: 2rem auto;
  text-align: center;
}

.filter_btn {
  padding: 0.5rem 2rem;
  background-color: #74b9ff;
  border: none;
  font-weight: 600;
  color: whitesmoke;
  transition: all 0.4s cubic-bezier(1, -0.16, 0.6, 1.35);
  border-radius: 5px;
}

.filter_btn:not(:last-child) {
  margin-right: 1rem;
}

.filter_btn:hover {
  background-color: #0984e3;
  cursor: pointer;
}

.filter_btn:active,
.filter_btn:focus {
  background-color: #0984e3;
}

p {
  margin-bottom: -5px !important;
}

@media screen and (max-width: 1200px) {
  .item_e {
    grid-template-columns: repeat(3, 1fr);
  }

  .filter_button {
    margin: 1rem auto;
    width: auto;
  }

  .filter_btn:not(:last-child) {
    margin-right: auto;
  }
}

/* Slider CSS */

.slider {
  position: relative;
  /* height: 100vh; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: -50px; */
}

.image {
  width: 900px;
  height: 450px;
  border-radius: 10px;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: -50px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: -50px;
  font-size: 3rem;
  color: #000;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}

/* Sort Btn CSS */

.sort {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 8px 0px;
  margin: 15px;
}

.sort_s > .sort_data {
  font-size: 1.5rem;
}

.search_b > .sort_data {
  font-size: 1.5rem;
}

.search_b {
  margin-left: 60%;
}

.sort_ds {
  color: #121fcf;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
}

.sort_da {
  cursor: pointer;
  width: 168px;
  position: relative;
  background: aliceblue;
  border-radius: 16px;
  height: 40px;
  min-width: 136px;
}

.sort_dab {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  border: 1px solid #0898ec;
  border-radius: 16px;
  background: aliceblue;
  transition: border-radius 0.15s ease 0s;
}

.sort_da svg {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.lkvAzg {
  fill: #121fcf;
  flex-shrink: 0;
}

.search_bb {
  position: relative;
}

.huvkpK {
  border-radius: 16px;
  margin-left: auto;
}

.dGmcca {
  background-color: aliceblue;
  border: 0px;
  border-radius: 16px;
  box-shadow: rgb(74 74 104 / 10%) 0px 2px 2px -1px inset;
  border: 1px solid #0898ec;
  color: rgb(69, 42, 122);
  display: block;
  font-size: 16px;
  height: 40px;
  outline: 0px;
  padding: 0px 16px;
  width: 100%;
}

/* Profile Page CSS */

.pp {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pp2 {
  height: 260px;
  background-color: rgba(4, 4, 5, 0.03);
  position: relative;
}

.pp1 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pph {
  position: absolute;
  bottom: -30px;
  left: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  z-index: 10;
}

.ppi {
  border: 4px solid aliceblue;
  border-radius: 100%;
  position: relative;
}

.ppi1 {
  width: 120px;
  height: 120px;
  position: relative;
}

.ppimg {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 120px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
}

.pnt {
  margin: 52px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pntx {
  /* margin: 52px 0px 0px; */
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pntxt {
  margin: 0px;
  padding: 0px 16px;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pht {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  align-items: center;
  justify-content: center;
}

.pst {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
}

.pstx {
  text-decoration: none;
  color: inherit;
  font-size: 28px;
  line-height: 30.8px;
  font-family: inherit;
  font-weight: 700;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.pstxt {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
}

.rms {
  margin: 0px 0px 0px 8px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  display: inline;
}

.cpb {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.cpbt {
  text-decoration: none;
  color: rgba(4, 4, 5, 0.4);
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.btne {
  margin: 8px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.prbtn {
  margin: 20px 0px 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-direction: row;
  align-items: center;
}

.fbtn {
  background-color: #e4f0ff;
  border-color: rgb(0, 102, 255);
  color: #000;
}

.flbtn {
  height: 40px;
  /* background-color: rgba(4, 4, 5, 0.05); */
  padding-left: 22px;
  padding-right: 22px;
  min-width: auto;
  border: 1px solid transparent;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  font-size: 14px;
  /* color: rgb(4, 4, 5); */
  font-weight: 900;
  font-family: inherit;
  transition: all 0.12s ease-in-out 0s;
  transform-origin: center center;
  user-select: none;
}

.ppb2 {
  margin: 0px 0px 0px 8px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.pbt2 {
  display: inline-block;
}

.pbt3 {
  border-color: rgba(4, 4, 5, 0.1);
  background: transparent;
}

.pbt4 {
  height: 40px;
  width: 40px;
  border: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  outline: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 0.12s ease-in-out 0s;
  border-radius: 40px;
  background-color: rgba(0, 102, 255, 0.05);
  transform-origin: center center;
}

/* Report Option CSS */

.tippy-box {
  position: relative;
  background-color: rgb(255, 255, 255);
  color: rgb(4, 4, 5);
  border-radius: 5px;
  border: 0px solid rgba(255, 255, 255, 0.1);
  font-weight: normal;
  outline: 0px;
  box-shadow: rgb(4 4 5 / 20%) 0px 7px 36px -8px;
  transition: all 0.12s ease-in-out 0s;
}

.bYZZJZ {
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
}

.bbWfCl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 0px;
}

.evgNzS {
  display: flex;
  flex-flow: column;
}

.dIvkOO {
  cursor: pointer;
  color: rgb(4, 4, 5);
  transition: all 0.12s ease-in-out 0s;
  text-align: left;
  height: 38px;
  padding: 0px 12px;
  margin: 0px 12px;
  border-radius: 6px;
}

.dIvkOO:hover {
  background-color: #f7f7f7;
}

/* Share CSS */
.bYZZJZ {
  padding: 0px;
  font-size: 15px;
  font-weight: 500;
}

.bbWfCl {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 16px 0px;
}

.gXpHxv {
  margin: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.bRtXqT {
  text-decoration: none;
  color: inherit;
  font-size: 18px;
  line-height: 24.84px;
  font-family: inherit;
  font-weight: 900;
  text-align: center;
}

.eGqSvC {
  margin: 16px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  width: 290px;
}

.jBEPVW {
  flex-flow: row wrap !important;
  justify-content: space-around;
}

.jbxvPE {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.jBEPVW > * {
  width: 22%;
}

.hnlxBI {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  justify-content: center;
  align-items: center;
}

.dQqrst {
  border-color: rgba(4, 4, 5, 0.1);
  background: transparent;
}

.KGdWz {
  height: 40px;
  width: 40px;
  border: 1px solid transparent;
  align-items: center;
  cursor: pointer;
  outline: none;
  display: flex;
  justify-content: center;
  transition: all 0.12s ease-in-out 0s;
  border-radius: 40px;
  background-color: rgba(0, 102, 255, 0.05);
  transform-origin: center center;
}

.oMHNp {
  margin: 8px 0px 0px;
  padding: 0px;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

/* Navtabs CSS */

.nt {
  margin: 32px 0px 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1 !important;
}

.nt1 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1;
}

.nt2 {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: -1;
  position: relative;
}

.dlSpeG {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-flow: row nowrap;
  align-items: center;
}

.iQUCaz > :not(:last-child) {
  margin-right: 16px;
}

.dJLimD {
  height: 30px;
  color: rgba(4, 4, 5, 0.4);
  justify-content: center;
  position: relative;
  padding-bottom: 2px;
}

.erMVgR {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.eSHvkk {
  white-space: nowrap;
}

.eqDhyJ {
  text-decoration: none;
  color: inherit;
  transition: all 0.12s ease-in-out 0s;
  font-size: 18px;
  line-height: 20.7px;
  font-family: inherit;
  font-weight: 900;
}

.jyJZLg {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(4, 4, 5, 0);
  height: 2px;
  width: 100%;
}

.bvKcKO {
  margin: 0px;
  padding: 0px;
  align-items: stretch;
  border-bottom: 2px solid #121fcf;
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  transition: all 0.12s ease-in-out 0s;
}

/* Explore Card Head CSS */

.ch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.chd {
  display: flex;
  flex-direction: row;
  /* z-index: -1; */
}

.eesVrg {
  text-decoration: none;
  color: rgb(0, 102, 255);
}

.fnyloc {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.ABQJT {
  width: 26px;
  height: 26px;
  position: relative;
}

.gtRctY {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  border-radius: 26px;
  width: 26px;
  height: 26px;
  overflow: hidden;
  /* background-color: rgb(255, 255, 255); */
}

.chd > :not(:first-child) {
  margin-left: -10px;
}

.fCQiqA {
  display: inline-block;
}

/* CardPOptn CSS */

.eHfBxm {
  cursor: pointer;
  color: rgb(4, 4, 5);
  transition: all 0.12s ease-in-out 0s;
  text-align: left;
  min-height: 32px;
  padding: 8px 12px;
  margin: 0px 12px;
  border-radius: 6px;
}

.erMVgR {
  width: auto;
  color: inherit;
  padding: 0px;
  background: transparent;
  border: none;
  cursor: pointer;
  font-style: inherit;
  font-variant: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: normal;
  overflow: visible;
  outline: none;
}

.eIGEyX {
  margin: 20px 0px 0px;
  padding: 0px;
  -webkit-box-align: stretch;
  align-items: stretch;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-direction: column;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
}

.fkBquX {
  margin: 0px;
  padding: 0px;
  border-width: 0px;
  border-style: solid;
  border-color: rgb(4, 4, 5);
  display: flex;
  flex-basis: auto;
  flex-shrink: 0;
  min-height: 0px;
  min-width: 0px;
  max-width: 100%;
  z-index: 0;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.eyxiok {
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: 700;
  color: rgb(4, 4, 5);
  margin-left: 8px;
}

.kmErAh {
  vertical-align: middle;
}

.hSKhZv > :not(:last-child) {
  margin-right: 16px;
}

.ktlGIb {
  transition: all 0.12s ease-in-out 0s;
  color: rgba(4, 4, 5, 0.6);
}

.eesVrg {
  text-decoration: none !important;
  color: rgb(0, 102, 255);
}

.fnyloc {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.chip {
  display: inline-block;
  padding: 0 25px;
  width: 100%;
  height: 35px;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
}

.chip img {
  float: left;
  margin: 5px 10px 0 -25px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
}

.tgl + .tgl-btn {
  outline: 0;
  /* display: block; */
  width: 2em;
  height: 1.1em;
  position: relative;
  cursor: pointer;
  user-select: none !important;
  margin-left: 5px;
  margin-right: 5px;
}
.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: block;
  content: "";
  width: 45%;
  height: 100%;
}
.tgl + .tgl-btn:after {
  left: 0;
}
.tgl + .tgl-btn:before {
  display: none;
}
.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-ios + .tgl-btn {
  background: #c0dcf5;
  border-radius: 2em;
  padding: 2px;
  transition: all 0.4s ease;
  border: 1px solid #518bdf;
}
.tgl-ios + .tgl-btn:after {
  border-radius: 2em;
  background: #518bdf;
  transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    padding 0.3s ease, margin 0.3s ease;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.tgl-ios + .tgl-btn:hover:after {
  will-change: padding;
}
.tgl-ios + .tgl-btn:active {
  box-shadow: inset 0 0 0 2em #e8eae9;
}
.tgl-ios + .tgl-btn:active:after {
  padding-right: 0.8em;
}
.tgl-ios:checked + .tgl-btn {
  background: #c0dcf5;
}
.tgl-ios:checked + .tgl-btn:active {
  box-shadow: none;
}
.tgl-ios:checked + .tgl-btn:active:after {
  margin-left: -0.8em;
}

/* Mint NFT CSS */

.nft-create-card {
  width: 340px;
  background: #fff;
  box-shadow: 0 0 1px rgb(20 21 26 / 10%), 0 3px 6px rgb(71 77 87 / 4%),
    0 1px 2px rgb(20 21 26 / 4%);
  border-radius: 34px;
  margin: 25px auto;
  padding-bottom: 10px;
}

.ant-form {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: "tnum", "tnum";
}

.nft-img-upload-cover,
.nft-img-uploaded {
  border-top-right-radius: 34px;
  border-top-left-radius: 34px;
}

.nft-img-upload-cover {
  height: 236px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.nft-create-card-bottom {
  padding: 8px 18px;
}

.input_1 {
  margin-bottom: 10px;
}

.input_1 Input,
.input_1 TextArea {
  border: 1px solid #8a8a8e;
  border-radius: 10px;
  padding: 10px;
}

.input_2 {
  border: 1px solid #8a8a8e;
  border-radius: 10px;
  padding: 10px;
}

.input_2 span {
  padding-left: 25px;
  font-size: 20px;
}

.nft-card-buy-button {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1773eb;
  border-radius: 17px;
}

.nft-card-buy-button {
  width: 300px;
}

.ant-btn > span {
  display: inline-block;
}
.nft-card-buy-button-text {
  text-align: center;
  flex: 0 0 120px;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 110%;
  color: #fff;
}

.avatar-uploader > .ant-upload {
  width: 200px;
  height: 200px;
}

.card-wrapper{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-around;
  width: 100%;
  padding-bottom: 50px;
}
.scontainer{
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between;
  width: 80%;

}
/* ------------------ Media Querry -------------------- */

@media screen and (max-width: 1024px) {
  .image {
    width: 500px;
    height: 250px;
    border-radius: 10px;
  }

  .right-arrow {
    right: -10px;
  }

  .left-arrow {
    left: -10px;
  }
}

@media screen and (max-width: 768px) {
  .right-arrow {
    font-size: 2rem;
    right: -80px;
  }

  .left-arrow {
    font-size: 2rem;
    left: -80px;
  }

  .filter_btn {
    margin: 1rem;
  }

  .item_e {
    grid-template-columns: repeat(2, 1fr);
  }

  .pntxt {
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 75px;
  }
}

@media screen and (max-width: 480px) {
  .search-bar-dropdown {
    margin-left: 50px;
  }

  .list-group-item {
    margin-left: -2px;
  }

  .image {
    width: 300px;
    height: 150px;
    border-radius: 10px;
  }

  .right-arrow {
    right: 2px;
    font-size: 1rem;
  }

  .left-arrow {
    left: 2px;
    font-size: 1rem;
  }

  .filter_btn {
    margin: 0.5rem;
  }

  .item_e {
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 20%;
  }

  .ex_app {
    padding: 0.5rem 2rem;
  }

  .btm-nav {
    display: block;
  }

  .right-cnt {
    display: none;
  }

  .sort_da {
    min-width: 168px;
  }

  .search_bb {
    width: 140px;
    display: block;
  }

  .search_b {
    margin-left: 16px;
  }

  .sort {
    margin: 0px;
    margin-top: 5px;
  }

  .search_b > .sort_data {
    font-size: 1rem;
  }

  .sort_s > .sort_data {
    font-size: 1rem;
  }

  .sort_s {
    margin-left: -8px;
  }

  .pntxt {
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 75px;
  }

  #tippy-5 {
    margin-top: 36% !important;
    transform: translate3d(182px, 396px, 0px) !important;
  }

  #tippy-4 {
    margin-top: 36% !important;
    transform: translate3d(33px, 396px, 0px) !important;
  }

  /* #tippy-10 {
    margin: 0px;
    transform: translate3d(33px, 396px, 0px);
  } */
}
@media(max-width: 980px){
  .table-text{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 80px !important;
  }
  .table-text1{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 120px !important;
  }
  .table-title{
    left: 50px;
    margin: 0px !important;
    padding: 0px !important;
  }
  .table-line{
    left: 75px;
  }
  .table-content{
    left: 50px;
    margin: 0px !important;
    padding: 0px !important;
  }
  .horiline {
    width: 250px;
  }
}
@media(max-width:780px){
  .card-wrapper{
    display: flex !important;
    flex-direction: column !important;
    padding-bottom: 50px;
  }
  .col_descd > p{
    font-size: 18px;
    margin: 5px;
    width: 250px !important;
    margin-left: -40px ;
  }
  .col_descd > span{
    font-size: 18px;
    margin: 5px;
    margin-left: -10px;
  }
  .provide-liq{
    font-size: 14px !important;
    line-height: 20px !important;
  }
  .card-img {
    align-items: center;
    position: absolute;
    height: 330px !important;
    object-fit: cover !important;
  }
  .card-div {
    position: relative;
    height: 390px!important;
    width: 400px!important;
    align-items: center !important;
    display: flex !important;
    justify-content: center !important;
  }
  .homecardimg img {
    top: 80px!important;
  }
  .homecarddesc{
    top: 180px !important;
  }
  .cardroutebtn {
    top: 100px !important;
  }
}