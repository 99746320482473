.countdown-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 90% !important;
    padding: 50px;
}
.countdown{
    padding: 0px 20px;
    color: #B42D17;
    font-size: 2rem;
    font-family: Philosopher;
}
.countdown-lbl{
    padding: 0px 20px;
    color: #fff;
    font-size: 1.2rem;
    letter-spacing: 3px;
    font-family: Philosopher;
    margin-top: -10px;
    margin-bottom: 10px !important;
}
.countdown-left{
    flex-basis: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
}
.countdown-right{
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    flex-basis: 50%;
}
.countdown-div{
    display: flex;
    flex-direction: row;
}
.btn-totalsold{
    position: absolute;
    top: -60px;
    left: 1px;
    width: 300px;
    object-fit: contain;
    height: 80px;
}
.total-sold{
    color: #B42D17;
    font-size: 1.6rem;
    font-family: Philosopher;
    position: absolute;
    left: 3%;
    top: -55px;
    min-width: 300px;
    z-index: 10;
}
.btn-totalbought{
    position: absolute;
    top: 20px;
    left: 1px;
    width: 300px;
    object-fit: contain;
    height: 80px;
}
.total-bought{
    color: #B42D17;
    font-size: 1.6rem;
    font-family: Philosopher;
    position: absolute;
    left: 3%;
    top: 25px;
    min-width: 300px
}
.cdown{
    display: flex;
    flex-direction: column;
}
.clock{
    height: 80px;
    width: 80px;
    object-fit: contain;
}
.pot{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: #B42D17;
    text-align: center;
}
.pot1{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 27px;
    color: #B42D17;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 15px 10px;
}
.modal-pot{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 22px !important ;
    line-height: 22px;
    color: #B42D17;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

}
.modal-title{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.close-btn{
    font-weight: 900;
    font-size: 26px;
    color: #B42D17;
    cursor: pointer;
}
.MuiBox-root {
    border-radius: 10px;
    border: none;
}
.modal-text{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 27px;
    color: #B42D17;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding: 15px 10px;
}
.stake{
    width: 100% !important;
}
.raffle-ticket{
    height: 280px !important;
    width: 450px !important;
    object-fit: cover;
}
.raffle-ticket-modal{
    height: 180px !important;
    width: 200px !important;
    object-fit: cover;
}
.row-2{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin: 30px;
    position: absolute;
    top: 130px;
}
.raffle-div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 20px;
    color: #fff;
    width: 100%;
}
.text-qty{
    background: #FFFFFF;
    border-radius: 5px; 
    padding: 5px;
    width: 120px;
    border: none;
    height: 30px;
}
.btn-buy{
    cursor: pointer;
    font-family: Philosopher;
    height: 80px;
    width: 140px;
    object-fit: cover;
}
.btn-claim{
    cursor: pointer;
    font-family: Philosopher;
    height: 120px;
    width: 180px;
    object-fit: cover;
}
.ticket-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
}
.buy-wrapper{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-basis: 60%;
    background-image: url('./images/tablebg.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex-direction: column;
    height: 380px;
}
.buybtn{
    position: relative;
    top: 20px;
}
.claimbtn{
    position: relative;
    top: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.multi{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 36px;
    color: #FFFFFF;
    padding: 10px;
}
.buybtntext{
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-family: Philosopher;
    position: absolute;
    left: 18%;
    top: -10px;
    cursor: pointer;
    width: 100px;
    text-align: center;
}
.claimbtn1{
  width: 160px;
  position: relative;
}
.claimbtntext{
    color: #fff;
    font-size: 1.2rem;
    line-height: 1.2rem;
    font-family: Philosopher;
    position: absolute;
    left: 15%;
    top: 15px;
    cursor: pointer;
    width: 100px;
    text-align: center;
}
.tokendropdown{
    border-radius: 7px;
    padding: 5px 20px;
    height: 40px;
    width: 120px !important;
    box-sizing: border-box;
    border: none;
    font-size: 1.5rem;
    font-family: Philosopher;
    color: #B42D17;
    cursor: pointer;
}
.dd-wrapper{
    display: flex;
    align-items:center;
    justify-content: flex-end;
    width: 50%;
}
.tokendropdownopt{
}
.closed-counter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
}
.closed-counter > h1{
    font-size: 40px !important;
}
.closed-counter > h4{
    font-size: 20px !important;
}
@media screen and (max-width: 880px) {
    .stake_container{
        width: 95%;
    }
    .row-2{
        flex-direction: column;
        width: 100%;
        margin-top: -20px;
    }
    .raffle-div{
        flex-direction: column !important;
        align-items: center;
        justify-content: center;
        margin-bottom: 30px;
    }
    .multi{
        padding: 5px;
    }
    .pot{
        padding: unset;
        font-size: 1.2rem;
    }
    .pot1{
        font-size: 1.2rem;
    }
    .countdown-container{
        flex-direction: column-reverse;
        margin: 20px 0px;
        align-items: center;
        display: flex;
        padding: 20px;
    }
    .countdown-right {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-basis: 100%;
    }

    .countdown-left{
        flex-basis: 100%;
        margin-top: 10px;
    }
    .raffle-wrapper{
        flex-direction: column !important;
    }

    .btn-totalbought{
        display: flex;
        align-items: center;
        justify-content: center;
        position: unset;
    }
    .total-bought{
        top: 18px;
        left: 20px;
    }

    .raffle-ticket{
        height: 250px !important;
        width: 350px !important;
        object-fit: contain;
    }
    .buy-wrapper{
        width: 95%;
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.1);
        box-shadow: inset 0px 4px 20px #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        height: 400px !important;
        flex-basis: unset;
    }
    .dd-wrapper{
        justify-content: center;
        align-items: center;
        margin-top: -80px !important;
    }
    .buybtn{
        top: 100px;
    }
  }

  /* -------------winners ------------- */

  .teammember-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    margin-bottom: 20px;
}


.down-card{
    margin-top: 10%;
}

.team-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    height: 400px !important;
    margin-bottom: 90px;
    flex-basis: 80%;
}

.teams-card-div{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-left: 40px;
    margin-right: 40px;
    transition: transform 450ms;
}
.teams-card-div:hover{
    transform: scale(1.08);
}
.team-header{
    background-image: url("./images/teamNamePlate.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 40px;
}
.team-header  > p {
    Font-family: Philosopher;
    font-style: Bold;
    font-size: 18px;
    line-height: 20px;
    text-align: Center;
    color: #88461F;
}
.teammember{
    font-family: Philosopher;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
}
.teammember-details{
    font-family: Philosopher;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    width: 250px;
    margin-top: 10px;
}
.team-title{
    font-family: Philosopher;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: 47px;
    letter-spacing: 0em;
    text-align: center;
    margin: 0;
    padding: 0;
    color: #fff;
    margin-top: 1rem;
}
.team-picture{
    position: relative;
    height: 200px;
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.team-picture > img {
    object-fit: contain;
}
@media(max-width: 1100px){
    .team-title{
        margin-bottom: 0px;
    }
    .team-div{
        flex-basis: 100%;
    }
    .teams-card-div{
        margin: 0px 70px;
    }
    .teammember-div{
        height: 1600px !important;
        padding-top: 100px;
    }
}
@media(max-width:840px){
    .teammember-div{
        height: 2000px !important;
    }
}
@media(max-width:580px){
    .teammember-div{
        height: 2000px !important;
        padding-top: 0px;
    }
    .team-title{
        margin-top: 0px;
    }
    .teams-card-div{
        margin: 0px;
        margin-top: 50px;
    }
    .team-div{
        margin-top: 10px !important;
        flex-basis: 100%;
    }
}