* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.stake{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 650px;
}

.stake_container {
  width: 90%;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 4px 20px #FFFFFF;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
}
.wnc-div{
  display: flex;
  align-items: center;
  justify-content: center;
}
.wallet-not-connected{
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 4px 20px #FFFFFF;
  border-radius: 10px;
  width: 45%;
  padding: 5px 20px;
  height: 50px;
  font-family: Philosopher;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 27px;
  text-align: center;
  color: #B42D17;
  margin-bottom: 50px;
}
.cc{
  color: #fff;
  background-color: transparent !important;
  flex-basis: 60%;
}
.cc2{
  flex-basis: 40%;
  margin-top: -50px;
}
a:hover{
  text-decoration: none !important;
}
h4:hover{
  text-decoration: none !important;
  color: #B42D17;
}
.logo{
  color: #fff;
  font-size: 35px;
  line-height: 80px;
  padding: 0 100px;
  font-weight: 700; 
  font-family: Staatliches;
}
.logo:hover{
  text-decoration: none;
}
.stake_container h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.stake_container h6 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
  font-size: 16px;
}

.create_c {
  background: transparent;
  margin: 25px;
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #fff;
  width: 80%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.rp {
  display: flex;
  justify-content: center;
  align-items: center;
}

.col-r,
.col-p {
  background-color: #ffffff;
  width: 525px;
  height: 220px;
  border-radius: 15px;
  /* margin-bottom: 45px; */
}

.col-p h4 {
  text-align: center;
  padding: 25px;
}

.col-r h4 {
  text-align: center;
  padding: 25px;
}

.ll {
  height: 50px;
  width: 2px;
  color: #dbeafe;
}

.col-r .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.col-p .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 35px;
}

.card_sec {
  margin: 25px 70px;
  padding-bottom: 50px;
}

.card_sec .cols {
}

.img_cont {
  margin: 5px 75px;
  padding: 20px;
  text-align: center;
}

.img_cont img {
  position: absolute;
  top: 60px;
  left: 130px;
  padding: 10px;
  border-radius: 150px;
  background: #f3f0e2;
  height: 100px;
  width: 100px;
  box-shadow: inset 9px 9px 17px #d3d1c5, inset -9px -9px 17px #ffffff;
}
.homecarddesc{
  text-align: center;
  padding: 15px;
  position: absolute;
  top: 160px;
  left: 55px;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  font-size: 14px;
  color: #B42D17;
}
.homecardimg img {
  top: 60px !important;
}
.unboundtext{
  display: flex;
  align-items: center;
  justify-content:center;
  width: 100%;
  height: 40px;
}
.col_descd {
  text-align: center;
  padding: 10px 10px;
  position: absolute;
  top: 160px;
  width: 100%;
  margin-left: -5%;
  display: flex;
  align-items: center;
  justify-content: center !important;
  flex-direction: column;
}

.buttons_sec {
  align-items: center;
  padding: 0px 10px;
}
.row {
  margin: 0 !important;
}
.cardbtn{
  width: 90px;
  height: 35px;
  padding: 5px 20px;
  margin: 5px;
  background-color: #fff;
  color: #B42D17;
  z-index: 10;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 10px;
  margin-top: 150px;
  position: absolute;
  cursor: pointer;
  font-family: Philosopher;
}
.radios{
  height: 15px;
  width: 15px;
  font-size: 30px;
  margin: 5px 5px 5px 5px;
  cursor: pointer;
}
.staketoggle1{
  position: absolute;
  width: 90px;
  top: 235px;
  left: 105px;
}
.staketoggle2{
  position: absolute;
  width: 90px;
  top: 235px;
  left: 180px;
}
.unstakebtn{
  position: absolute;
  width: 90px;
  top: 150px;
  left: 130px;
}
.unstakeloadbtn{
  position: absolute;
  width: 150px;
  top: 190px;
  left: 130px;
}
.unstaketxt{
  position: absolute;
  width: 50%;
  top: 260px;
  left: 80px;
  border-radius: 7px;
  padding: 2px 5px;
  margin: 3px;
  box-sizing: border-box;
  border: 1px solid #B42D17;
}
.btn1{
  width: 90px;
  top: 80px;
  left: 70px;
}
.btn2{
  width: 90px;
  top: 80px;
  left: 170px;
}
.claimbtn{
  width: 150px;
  top: 135px;
  left: 100px;
}
.cardroutebtn{
  width: 200px;
  top: 80px;
  left: 75px;
}
.leader-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  min-height: 650px;
  margin-top: 3rem;
}
.leader {
  width: 80%;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: inset 0px 4px 20px #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  position: relative;
  padding: 10px 5px;
  margin: 10px 0px;
  color: #B42D17;
  font-family: Philosopher;
}
.leader-rank{
  flex-basis: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
}
.leader-address{
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
}
.leader-amount{
  flex-basis: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0 !important;
  font-size: 18px;
}
.notes-div{
  width: 80%;
}
.leader-notes{
  color: white;
  font-size: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content:center;
}
.truncated-address{
  display: none;
}
@media screen and (max-width: 1280px) {
  .col-r,
  .col-p {
    width: 450px;
  }

  .card_sec .cols {
   /* height: 320px;*/
    margin-left: 25px;
  }

  .img_cont {
    margin: 5px 60px;
  }
}

@media screen and (max-width: 1024px) {
  .col-r,
  .col-p {
    width: 325px;
  }

  .img_cont {
    margin: 5px 40px;
    padding: 20px 80px;
  }

  .ll {
    display: none;
  }

  .buttons_sec {
    padding: 0px;
  }

  .row {
    flex-wrap: nowrap !important;
  }
}

@media screen and (max-width: 768px) {
  .img_cont {
    margin: 0px;
    padding: 20px 80px;
  }

  .row {
    flex-wrap: nowrap !important;
  }

  .cc h4 {
    font-size: 20px;
  }

  .cc p {
    font-size: 12px;
  }

  .cc2 button {
    font-size: 12px;
  }

  .col-r h4 {
    font-size: 20px;
  }

  .col-p h4 {
    font-size: 20px;
  }

  .col-left p {
    font-size: 12px;
  }

  .col-right p {
    font-size: 12px !important;
  }

  .col-r,
  .col-p {
    width: 250px;
    margin-left: 10px;
  }

  .card_sec .cols {
    height: 250px;
    margin-left: 25px;
  }
}
@media screen and (max-width: 880px) {
  .truncated-address{
    display: block;
  }
  .full-address{
    display: none;
  }
}
@media screen and (max-width: 480px) {
  .stake_container {
    margin: 10px;
  }

  .stake_container h1 {
    font-size: 24px;
  }

  .stake_container h6 {
    font-size: 12px;
  }

  .col-p,
  .col-r {
    width: 170px;
    margin-left: 2px;
  }

  .col-p h4 {
    padding: 10px 5px;
  }

  .col-r h4 {
    padding: 10px 5px;
  }

  .card_sec {
    margin: 10px;
  }

  .card_sec .cols {
    margin: 5px 8px 5px 12px;
  }

  .cc p {
    font-size: 10px;
  }

  .cc2 button {
    font-size: 10px;
    margin-top: 20px;
  }

  .col_descd {
    font-size: 12px;
    padding: 0px;
  }

  .img_cont {
    text-align: center;
    padding: 10px 5px;
  }
}

@media(max-width: 980px){
  .create_c {
    flex-direction: column !important;
    margin: 5px;
    padding: 5px;
    width: 95%;
    justify-content: center;
    height: 150px;
  }
  .cc{
    flex-basis: 100%;

  }
  .cc2{
    flex-basis: 100%;
    margin-top: unset;
  }
  .add-liq-btn {
    top: 20px !important;
    left: -150px;
  }
  .addliq{
    top: 10px;
    left: -150px;
  }
  .wallet-not-connected{
    width: 80%;
    padding: 5px 20px;
    height: 50px;
    margin-bottom: 80px;
    margin-top: 30px;
    font-size: 18px;
    line-height: 20px;
  }
  .tablebgimg {
    width: 400px !important;
    height: 210px !important;
  }
  .table-text{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 100px !important;
  }
  .table-text1{
    font-family: Quicksand;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 120px !important;
  }
  .leader{
    width: 98%;
  }
  .leader-address{
    flex-basis: 60% !important;
  }
}

/*----------switch -------------*/
.faq{
  justify-content: flex-start !important;
  padding-top: 50px;
}
.steps{
  margin: 10px 20px !important;
}
details{
  width: 80%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 30px;
}
summary {
  width: 80%;
  font-size: 1.25rem;
  font-weight: 600;
  background-color: #fff;
  color: #B42D17;
  padding: 1rem;
  margin-bottom: 1rem;
  outline: none;
  border-radius: 0.25rem;
  text-align: left;
  cursor: pointer;
  position: relative;
}
details > summary::after {
  position: absolute;
  content: "+";
  right: 20px;
}
details[open] > summary::after {
  position: absolute;
  content: "-";
  right: 120px;
}
details > summary::-webkit-details-marker {
  display: none;
}
details[open] summary ~ * {
  animation: sweep .5s ease-in-out;
}
@keyframes sweep {
  0%    {opacity: 0; margin-top: -10px}
  100%  {opacity: 1; margin-top: 0px}
}